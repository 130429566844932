<template>
  <b-container class="component-recategorize-multisteps-view">
    <b-row no-gutters>
      <b-col cols="12" class="mt-3 mb-2">

        <div class="bordered-table">
          <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
          <div class="col-md-10 pl-0" v-html="$t(translationPath + 'description')"></div>

          <b-table
            class="spirecta-simple-table accounts-table mt-4"
            show-empty
            hover
            striped
            ref="multipleEdit"
            :items="transactionsViewItems"
            :fields="transactionsFields"
            :busy="loading"
            :tbodyTrClass="tableRowSetClass"
            stacked="lg"
            :emptyText="'No New Transaction'"
          >
            <!-- date -->
            <template v-slot:cell(date)="row">
              <span class="text-nowrap">{{row.value}}</span>
            </template>

            <!-- amount -->
            <template v-slot:cell(amount)="row">
              <span :class="'text-nowrap font-weight-bold transaction_' + row.item.transaction_subtype">{{row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true,0 )}}</span>
            </template>

            <!-- credit_account -->
            <template v-slot:cell(credit_account)="row">
              {{ row.value.title }}
            </template>

            <!-- debit_account -->
            <template v-slot:cell(debit_account)="row">
              {{ row.value.title }}
            </template>

            <!-- transaction_subtype -->
            <template v-slot:cell(transaction_subtype)="row">
                <i18n :path="'common.transaction_subtypes.' +row.value" tag="span" class="text-gray" style="font-size: 90%">
                  <template v-slot:cr_account v-if="Object.prototype.hasOwnProperty.call(row.item,'credit_account')">
                    <b-link :to="'/reports/performance/accounts/' +row.item.credit_account.id +'/view'" class="subtype_description_link">{{ currentCOA.prefix_account_title_with_code ? row.item.credit_account.title_with_code  : row.item.credit_account.title }}</b-link>
                  </template>
                  <template v-slot:dr_account v-if="Object.prototype.hasOwnProperty.call(row.item,'debit_account')">
                    <b-link :to="'/reports/performance/accounts/' +row.item.debit_account.id +'/view'" class="subtype_description_link">{{ currentCOA.prefix_account_title_with_code ? row.item.debit_account.title_with_code  : row.item.debit_account.title }}</b-link>
                  </template>
                </i18n>
            </template>

            <template v-slot:row-details="row">
              <template v-if="hasTagActions(row.item.tagActions) || row.item.tagsToShow.length">
                <span class="mr-3">{{ $t(translationPath + 'table_tags.tags') }}</span>
                <template v-if="row.item.tagsToShow">
                  <span v-for="v in row.item.tagsToShow" :key="v.id" class="tag mr-3">
                    <span>{{ v.title }}</span>
                  </span>
                </template>
                <template v-if="hasTagActions(row.item.tagActions)">
                  <span v-for="v in row.item.tagActions.add_tag" :key="v" class="tag text-primary mr-3">
                    <span>{{ v }}</span>
                  </span>
                  <span v-for="v in row.item.tagActions.remove_tag" :key="v" class="tag text-secondary mr-3">
                    <span>{{ v }}</span>
                  </span>
                  <span v-for="i, v in row.item.tagActions.replace_tag" :key="v" class="tag mr-3">
                    <span class="text-secondary">{{ v }}</span> → <span class="text-primary">{{ i }}</span>
                  </span>
                </template>
              </template>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'

export default {
  name: 'RecategorizeMultiStepsView',
  props: {
    transactionsView: {
      type: Array,
      default: function () {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    formatAmount
  },
  data () {
    return {
      translationPath: 'transactions.re-categorize.step2.',
      complexTransactionCount: 0,
      transactionsViewItems: []
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    transactionsFields () {
      return [
        { key: 'date', label: this.$t('common.date'), sortable: false },
        { key: 'title', label: this.$t('common.title'), sortable: false },
        { key: 'amount', label: this.$t('common.amount'), sortable: false, class: 'text-right' },
        // { key: 'debit_account', label: this.$t(this.translationPath + 'debit_account'), sortable: false },
        // { key: 'credit_account', label: this.$t(this.translationPath + 'credit_account'), sortable: false },
        { key: 'transaction_subtype', label: this.$t(this.translationPath + 'transaction_subtype'), sortable: false },
        { key: 'percentage', label: this.$t('common.percentage'), class: 'text-right', formatter: (val) => `${val} %` }
      ]
    }
  },
  methods: {
    hasTagActions (tagActions) {
      if (!tagActions) {
        return false
      }
      return tagActions.add_tag.length || tagActions.remove_tag.length || (Object.keys(tagActions.replace_tag)).length
    },
    tableRowSetClass (item) {
      if (!item) {
        return ''
      }
      if (Object.prototype.hasOwnProperty.call(item, 'transaction_subtype')) {
        return 'tr-' + item.transaction_subtype.replace(/_/, '-')
      }

      return ''
    },
    parseTransactions (el) {
      return {
        id: el.id,
        title: el.title,
        amount: el.amount,
        date: el.date,
        credit_account: el.credit_account,
        debit_account: el.debit_account,
        percentage: el.percentage,
        transaction_subtype: el.transaction_subtype,
        description: el.description,
        tagActions: el.tagActions,
        tagsToShow: el.tagsToShow,
        _showDetails: el._showDetails
      }
    },
    processTransactions (val) {
      this.complexTransactionCount = val.filter(
        (el) => el.transaction_subtype === 'complex_transaction'
      ).length
      this.transactionsViewItems = val
        .filter((el) => el.transaction_subtype !== 'complex_transaction')
        .map((el) => this.parseTransactions(el))
    }
  },
  watch: {
    transactionsView: {
      handler: function (val) {
        if (val) {
          this.processTransactions(val)
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
