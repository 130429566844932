<template>
  <b-container class="component-recategorize-wizard-step01">
    <b-row no-gutters>
      <!-- MULTIPLE EDIT FILTER BOX-->
      <b-col cols="12" class="mt-3 mb-2" v-if="showMultipleEdit">
        <div class="bordered-table">

          <h2>{{ $t('common.mass_edit') }}</h2>
          <p>{{ $t(translationPath + 'description_mass_edit') }}</p>
          <b-form-group
            label-cols="12"
            label-cols-md="3"
            :label="$t('common.date')"
            :state="multipleDateOperatorState"
            :invalid-feedback="multipleDateOperatorInvalidFeedback"
          >
            <b-row no-gutters>
              <b-col cols="12" md="3" class="pr-0 pr-md-3">
                <b-form-select
                  v-model="multipleDateOperator"
                  :options="dateMassEditOptions"
                  :state="multipleDateOperatorState"
                />
              </b-col>
              <b-col>
                <b-form-group
                  :state="multipleDateValueState"
                  :invalid-feedback="multipleDateValueInvalidFeedback"
                >
                  <b-form-input
                    v-model="multipleDateValue"
                    :type="multipleDateOperator !== 'set_date' ? 'number' : 'text'"
                    :placeholder="multipleDateOperator !=='set_date'? '': 'YYYY-MM-DD'"
                    :state="multipleDateValueState"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group
            label-cols="12"
            label-cols-md="3"
            :label="$t('common.amount')"
            :state="multipleAmountOperatorState"
            :invalid-feedback="multipleAmountOperatorInvalidFeedback"
          >
            <b-row no-gutters>
              <b-col cols="12" md="3" class="pr-0 pr-md-3">
                <b-form-select
                  v-model="multipleAmountOperator"
                  :options="amountMassEditOptions"
                  :state="multipleAmountOperatorState"
                />
              </b-col>
              <b-col>
                <b-form-group
                  :state="multipleAmountValueState"
                  :invalid-feedback="multipleAmountValueInvalidFeedback"
                >
                  <currency-input
                    ref="CurrencyInputAmountValue"
                    id="multiple-amount-value"
                    v-model="multipleAmountValue"
                    :class="amountValidationClass"
                    :precision="2"
                    :state="multipleAmountValueState"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group
            label-cols="12"
            label-cols-md="3"
            :label="$t('common.title')"
            :state="multipleTitleOperatorState"
            :invalid-feedback="multipleTitleOperatorInvalidFeedback"
          >
            <b-row no-gutters>
              <b-col cols="12" md="3" class="pr-0 pr-md-3">
                <b-form-select
                  v-model="multipleTitleOperator"
                  :options="titleMassEditOptions"
                  :state="multipleTitleOperatorState"
                />
              </b-col>
              <b-col>
                <b-form-group
                  :state="multipleTitleValueState"
                  :invalid-feedback="multipleTitleValueInvalidFeedback"
                >
                  <b-form-input
                    v-model="multipleTitleValue"
                    :state="multipleTitleValueState"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group label-cols="12" label-cols-md="3" :label="$t(translationPath + 'debit_account')">
            <custom-select
              :options="categoryOptions"
              v-model="multipleSource"
              groupingValues="accounts"
              groupingLabel="label"
              :placeholder="''"
              :search-placeholder="$t('common.type_to_filter')"
              :noResultText="$t('common.no_option_found')"
              :sortGroupLabel="false"
              label="label"
            />
          </b-form-group>

          <b-form-group label-cols="12" label-cols-md="3" :label="$t(translationPath + 'credit_account')">
            <custom-select
              :options="categoryOptions"
              v-model="multipleCategory"
              groupingValues="accounts"
              groupingLabel="label"
              :placeholder="''"
              :search-placeholder="$t('common.type_to_filter')"
              :noResultText="$t('common.no_option_found')"
              :sortGroupLabel="false"
              label="label"
              @select="handleSourceChange"
            />
          </b-form-group>

          <b-form-group label-for="account_percentage" label-class="customPadding" label-cols-md="3" label-align-lg="left">
            <template slot="label">{{$t('common.masseditform.labels.percentage')}}</template>
            <template slot="description">{{ $t('common.masseditform.percentage_help_text' , {active: percentage, passive: passivePercentageValue })}}</template>
            <b-col sm="12" md="10" class="float-left paddingLeftHide mobilePaddingLR-0 mb-2 mb-md-0 pb-2 pt-2 pl-0">
              <b-form-input id="account_percentage_slider" class="slider" type="range" min="0" max="100" step="1" v-model="percentage"></b-form-input>
            </b-col>
            <b-col sm="12" md="2" class="float-left mb-2 paddingLeftHide mobilePaddingLR-0 pr-0">
              <b-input-group append="%">
                <b-form-input id="account_percentage" type="number" min="0" max="100" v-model="percentage" :disabled="true"></b-form-input>
              </b-input-group>
            </b-col>
          </b-form-group>

          <b-form-group
            v-show="true"
            label-cols="12"
            label-cols-md="3"
            :label="$t(translationPath + 'tags')"
            :state="multipleTagOperatorState"
            :invalid-feedback="multipleTagOperatorInvalidFeedback"
          >
            <b-row no-gutters>
              <b-col cols="12" md="3" class="pr-0 pr-md-3">
                <b-form-select
                  v-model="multipleTagOperator"
                  :options="tagMassEditOptions"
                  :state="multipleTagOperatorState"
                />
              </b-col>
              <b-col v-if="multipleTagOperator !== 'replace_tag'">
                <b-row no-gutters>
                  <b-col cols="10">
                    <b-form-group
                      :state="multipleTagValueState"
                      :invalid-feedback="multipleTagValueInvalidFeedback"
                    >
                      <autocomplete-input
                        v-model="multipleTagValue"
                        :values="tagTitles"
                        :state="multipleTagValueState"
                        :key="renderKey"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="2" class="text-right">
                    <b-button class="btn btn-sm mt-2" variant="primary" @click="onProcessTagClick">{{ $t('common.ok') }}</b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-else>
                <b-row no-gutters>
                  <b-col>
                    <b-form-group
                      label-cols="12"
                      label="Tags to replace"
                      :state="multipleTagValueState"
                      :invalid-feedback="multipleTagValueInvalidFeedback"
                    >
                      <autocomplete-input
                        v-model="multipleTagValue"
                        :values="tagTitles"
                        :state="multipleTagValueState"
                        :key="renderKey"
                      />
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label="Raplace with"
                      :state="multipleTagValueAdditionalState"
                      :invalid-feedback="multipleTagValueAdditionalInvalidFeedback"
                    >
                      <autocomplete-input
                        v-model="multipleTagValueAdditional"
                        :values="tagTitles"
                        :state="multipleTagValueAdditionalState"
                        :key="renderKey"
                      />
                    </b-form-group>
                    <b-button class="btn btn-sm mt-2" variant="primary" @click="onProcessTagClick">{{ $t('common.ok') }}</b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row no-gutters>
              <b-col cols="12" md="3" class="pr-0 pr-md-3"></b-col>
              <b-col cols="12" md="9">
                <span v-for="v in tagSet.add_tag" :key="v" class="tag text-primary mr-3">
                  <b-button
                    :title="$t('common.delete')"
                    class="px-0 mr-1"
                    @click="onTagDeleteClick(v, 'add_tag')"
                  >
                    <i class="flaticon solid trash-3 text-danger"></i
                  ></b-button>
                  <span>{{ v }}</span>
                </span>
                <span v-for="v in tagSet.remove_tag" :key="v" class="tag text-secondary mr-3">
                  <b-button
                    :title="$t('common.delete')"
                    class="px-0 mr-1"
                    @click="onTagDeleteClick(v, 'remove_tag')"
                  >
                    <i class="flaticon solid trash-3 text-danger"></i
                  ></b-button>
                  <span>{{ v }}</span>
                </span>
                <span v-for="i, v in tagSet.replace_tag" :key="v" class="tag mr-3">
                  <b-button
                    :title="$t('common.delete')"
                    class="px-0 mr-1"
                    @click="onTagDeleteClick(v, 'replace_tag')"
                  >
                    <i class="flaticon solid trash-3 text-danger"></i
                  ></b-button>
                  <span class="text-secondary">{{ v }}</span> → <span class="text-primary">{{ i }}</span>
                </span>
              </b-col>
            </b-row>
          </b-form-group>

          <b-col cols="12" no-gutters class="px-0 text-right">
            <b-button class="mr-2" variant="outline-secondary" @click="() => showMultipleEdit = false">
              {{$t('common.cancel')}}
            </b-button>
            <b-button variant="primary" @click="multipleEditHandler">
              {{$t('common.ok')}}
            </b-button>
          </b-col>
        </div>
      </b-col>
      <!-- END MASS EDIT FORM -->

      <b-col cols="12" class="mt-3 mb-2">
        <div class="bordered-table">

          <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
          <b-row no-gutters>
            <b-col class="col-md-10">
              <div v-html="$t(translationPath + 'description')"></div>
            </b-col>
            <b-col class="text-right pb-3" align-self="end">
              <!-- MASS EDIT BUTTON -->
              <b-button size="sm" @click="() => showMultipleEdit = true" v-if="multipleSelectedRows.length > 0 && !showMultipleEdit">
                {{ $t('common.mass_edit') }}
              </b-button>
            </b-col>
          </b-row>

          <b-card class="table-wrapper">
            <b-table
              class="spirecta-simple-table accounts-table"
              show-empty
              hover
              striped
              ref="multipleEdit"
              :items="transactionsViewItems"
              :fields="transactionsFields"
              :busy="loading"
              stacked="lg"
              :emptyText="$t('common.loading')"
            >
              <!--loader-->
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- SLOT: TITLE -->
              <template v-slot:cell(title)="row">
                <b-form-group class="mb-0" :state="row.item.errors.title" :invalid-feedback="row.item.errors.title === false? $t(viewTranslationPath + 'errors.title'): ''">
                  <b-form-input v-model.trim="row.item.title" @input="(value) => validateSingleColumn(value, row, 'title')" :state="row.item.errors.title" />
                </b-form-group>
              </template>

              <!-- SLOT: DATE -->
              <template v-slot:cell(date)="row">
                <b-form-group class="mb-0" :state="row.item.errors.date" :invalid-feedback="row.item.errors.date === false ? $t(viewTranslationPath + 'errors.date'): ''">
                  <datepicker
                    input-class="form-control"
                    v-model="row.item.date"
                    :language="selectedLang"
                    :format="'yyyy-MM-dd'"
                    :typeable="true"
                    :state="row.item.errors.date"
                    :class="{
                      'is-invalid': row.item.errors.date === false,
                      'is-valid': row.item.errors.date === true,
                    }"
                    :monday-first="startWeekByMonday"
                    @input="(value) => validateSingleColumn(value, row, 'date')"
                  ></datepicker>
                </b-form-group>
              </template>

              <!-- SLOT: AMOUNT -->
              <template v-slot:cell(amount)="row">
                <b-form-group class="mb-0" :state="row.item.errors.amount" :invalid-feedback="row.item.errors.amount === false ? $t(viewTranslationPath + 'errors.amount'): ''">
                  <currency-input
                    :ref="'CurrencyInputAmount' + row.item.id"
                    v-model="row.item.amount"
                    :distractionFree="true"
                    @input="(value) => validateSingleColumn(value, row, 'amount')"
                  />
                </b-form-group>
              </template>

              <!--SLOT: credit_account-->
              <template v-slot:cell(credit_account)="row">
                <template v-if="row.item.credit_account">
                  <b-form-group class="mb-0" :state="row.item.errors.credit_account" :invalid-feedback="row.item.errors.credit_account === false ? $t(viewTranslationPath + 'errors.credit_account'): ''">
                    <custom-select
                      v-model="row.item.credit_account"
                      :options="categoryOptions"
                      groupingValues="accounts"
                      groupingLabel="label"
                      :placeholder="''"
                      :search-placeholder="$t('common.type_to_filter')"
                      :noResultText="$t('common.no_option_found')"
                      :sortGroupLabel="false"
                      @open="handleCategoryOpen(row.item.credit_account.type)"
                      @input="(value) => validateSingleColumn(value, row, 'credit_account')"
                      @select="(value) =>(row.item.percentage = value.default_active_percentage)"
                      v-bind:class="{
                        'is-invalid': row.item.errors.credit_account === false,
                        'is-valid': row.item.errors.credit_account === true,
                      }"
                      label="label"
                    />
                  </b-form-group>
                </template>
              </template>

              <!--SLOT: debit_account-->
              <template v-slot:cell(debit_account)="row">
                <template v-if="row.item.debit_account">
                  <b-form-group class="mb-0" :state="row.item.errors.debit_account" :invalid-feedback="row.item.errors.debit_account === false ? $t(viewTranslationPath + 'errors.debit_account'): ''">
                    <custom-select
                      v-model="row.item.debit_account"
                      :options="categoryOptions"
                      groupingValues="accounts"
                      groupingLabel="label"
                      :placeholder="''"
                      :search-placeholder="$t('common.type_to_filter')"
                      :noResultText="$t('common.no_option_found')"
                      :sortGroupLabel="false"
                      @open="handleCategoryOpen(row.item.debit_account.type)"
                      @input="(value) => validateSingleColumn(value, row, 'debit_account')"
                      v-bind:class="{
                        'is-invalid': row.item.errors.debit_account === false,
                        'is-valid': row.item.errors.debit_account === true,
                      }"
                      label="label"
                    />
                  </b-form-group>
                </template>
                <template v-else>&nbsp;
                </template>
              </template>

              <!--SLOT: percentage-->
              <template v-slot:cell(percentage)="row">
                <percentage-input
                  v-model="row.item.percentage"
                  :max="100"
                  :min="0"
                  :value="row.value ? row.value : 0"
                ></percentage-input>
              </template>

              <!--HEAD: checkbox--->
              <template v-slot:head(checkbox)>
                <b-form-checkbox v-model="allSelected" :indeterminate="indeterminate" @change="toggleAll"></b-form-checkbox>
              </template>

              <!--SLOT: checkbox--->
              <template v-slot:cell(checkbox)="row">
                <b-form-checkbox-group v-model="multipleSelectedRows">
                  <b-form-checkbox :value="row.item" @change="(values) => selectCheckBoxRow(values, row.index)"></b-form-checkbox>
                </b-form-checkbox-group>
              </template>

              <template v-slot:row-details="row">
                <template v-if="hasTagActions(row.item.tagActions) || row.item.tagsToShow.length">
                  <span class="mr-3">{{ $t(translationPath + 'table_tags.tags') }}</span>
                  <template v-if="row.item.tagsToShow">
                    <span v-for="v in row.item.tagsToShow" :key="v.id" class="tag mr-3">
                      <b-button
                        :title="$t('common.delete')"
                        class="px-0 pt-0 mr-1"
                        @click="onExistedTagDeleteClick(v, row.item)"
                      >
                        <i class="flaticon solid trash-3 text-danger"></i
                      ></b-button>
                      <span>{{ v.title }}</span>
                    </span>
                  </template>
                  <template v-if="hasTagActions(row.item.tagActions)">
                    <span v-for="v in row.item.tagActions.add_tag" :key="v" class="tag text-primary mr-3">
                      <b-button
                        :title="$t(translationPath + 'table_tags.undo_add')"
                        class="px-0 pt-0 mr-1"
                        @click="onAddedTagDeleteClick(v, row.item)"
                      >
                        <i class="flaticon solid trash-3 text-danger"></i
                      ></b-button>
                      <span>{{ v }}</span>
                    </span>
                    <span v-for="v in row.item.tagActions.remove_tag" :key="v" class="tag text-secondary mr-3">
                      <b-button
                        :title="$t(translationPath + 'table_tags.undo_delete')"
                        class="px-0 pt-0 mr-1"
                        @click="onUndoTagDeleteClick(v, row.item)"
                      >
                        <i class="flaticon solid trash-3 text-danger"></i
                      ></b-button>
                      <span>{{ v }}</span>
                    </span>
                    <span v-for="i, v in row.item.tagActions.replace_tag" :key="v" class="tag mr-3">
                      <b-button
                        :title="$t(translationPath + 'table_tags.undo_replace')"
                        class="px-0 pt-0 mr-1"
                        @click="onUndoTagReplaceClick(v, i, row.item)"
                      >
                        <i class="flaticon solid trash-3 text-danger"></i
                      ></b-button>
                      <span class="text-secondary">{{ v }}</span> → <span class="text-primary">{{ i }}</span>
                    </span>
                  </template>
                </template>
              </template>
              <!-- END SLOTS -->
            </b-table>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import useComponentCurrencyInputMixin from '@/mixins/useComponentCurrencyInput'
import Datepicker from 'vuejs-datepicker'
import PercentageInput from '@/components/common/PercentageInput'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import CurrencyInput from '@/components/common/CurrencyInput'
import { mapState } from 'vuex'
import axios from 'axios'
import Loader from '@/components/common/Loader'
import CustomSelect from '@/components/common/CustomSelect'
import AutocompleteInput from '@/components/common/AutocompleteInput'
import _ from 'lodash'

export default {
  name: 'MultiStepsEdit',
  props: {
    transactions: {
      type: Array,
      default: function () {
        return []
      }
    },
    categoryOptions: {
      type: Array,
      default: function () {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [useComponentCurrencyInputMixin],
  components: {
    Datepicker,
    CurrencyInput,
    CustomSelect,
    Loader,
    PercentageInput,
    AutocompleteInput
  },
  data () {
    return {
      allSelected: false,
      indeterminate: false,
      showMultipleEdit: false,
      multipleSelectedRows: [],
      percentage: null,
      translationPath: 'transactions.re-categorize.step1.',
      startWeekByMonday:
        Object.prototype.hasOwnProperty.call(
          process.env,
          'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY'
        ) && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY
          ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true'
          : false,
      multipleCategory: null,
      multipleSource: null,
      multipleDateOperator: null,
      multipleDateValue: null,
      multipleAmountOperator: null,
      multipleAmountValue: 0,
      multipleTitleOperator: null,
      multipleTitleValue: null,
      multipleTagOperator: null,
      multipleTagValue: null,
      multipleTagValueAdditional: null,
      tagSet: { add_tag: [], remove_tag: [], replace_tag: {} },
      isMultipleEditFormSubmitted: false,
      isTagFormSubmitted: false,
      complexTransactionCount: 0,
      transactionsViewItems: [],
      viewTranslationPath: 'transactions.create_multiple.',
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      renderKey: 0
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    passivePercentageValue () {
      return 100 - this.percentage
    },
    breadcrumbItems () {
      return [
        {
          text: this.$t('common.header.header_menu.transactions.transactions'),
          to: '/transactions/view'
        },
        {
          text: this.$t('transactions.submenu.view'),
          to: '/transactions/view'
        },
        { text: '', to: '', active: true }
      ]
    },
    transactionsFields () {
      return [
        { key: 'date', label: this.$t('common.date'), sortable: false, class: 'td-date' },
        { key: 'title', label: this.$t('common.title'), sortable: false, class: 'td-title' },
        { key: 'amount', label: this.$t('common.amount'), sortable: false, class: 'td-amount' },
        { key: 'debit_account', label: this.$t(this.translationPath + 'debit_account'), sortable: false, class: 'td-debit_account' },
        { key: 'credit_account', label: this.$t(this.translationPath + 'credit_account'), sortable: false, class: 'td-credit_account' },
        { key: 'percentage', label: this.$t('common.percentage'), sortable: false, class: 'td-percentage' },
        { key: 'checkbox', label: '', sortable: false, class: 'td-checkbox' }
      ]
    },
    selectedIndexes () {
      return [...this.multipleSelectedRows].map(
        (valueItem) => valueItem.id)
    },
    validTransactions () {
      return this.transactionsViewItems.filter((el) => this.validateValues(el.amount) && this.validateValues(el.title) && this.validateValues(el.credit_account) && this.validateValues(el.debit_account))
    },
    dateMassEditOptions () {
      return [
        { value: null, text: '' },
        { value: 'set_date', text: this.$t('common.masseditform.operators.set_date') },
        { value: 'add_days', text: this.$t('common.masseditform.operators.add_days') },
        { value: 'add_months', text: this.$t('common.masseditform.operators.add_months') },
        { value: 'add_year', text: this.$t('common.masseditform.operators.add_year') },
        { value: 'subtract_days', text: this.$t('common.masseditform.operators.subtract_days') },
        { value: 'subtract_months', text: this.$t('common.masseditform.operators.subtract_months') },
        { value: 'subtract_years', text: this.$t('common.masseditform.operators.subtract_years') }
      ]
    },
    amountMassEditOptions () {
      return [
        { value: null, text: '' },
        { value: 'set_amount', text: this.$t('common.masseditform.operators.set_amount') },
        { value: 'multiply_with', text: this.$t('common.masseditform.operators.multiply_with') },
        { value: 'divide_by', text: this.$t('common.masseditform.operators.divide_by') },
        { value: 'subtract_by', text: this.$t('common.masseditform.operators.subtract_by') },
        { value: 'add', text: this.$t('common.masseditform.operators.add') }
      ]
    },
    titleMassEditOptions () {
      return [
        { value: null, text: '' },
        { value: 'set_title', text: this.$t('common.masseditform.operators.set_title') },
        { value: 'remove_part', text: this.$t('common.masseditform.operators.remove_part') },
        { value: 'append_part', text: this.$t('common.masseditform.operators.append_part') },
        { value: 'prefix_part', text: this.$t('common.masseditform.operators.prefix_part') },
        { value: 'lowercase', text: this.$t('common.masseditform.operators.lowercase') },
        { value: 'upper_first', text: this.$t('common.masseditform.operators.upper_first') },
        { value: 'capitalize', text: this.$t('common.masseditform.operators.capitalize') }
      ]
    },
    tagMassEditOptions () {
      return [
        { value: null, text: '' },
        { value: 'add_tag', text: this.$t('common.masseditform.operators.add_tag') },
        { value: 'remove_tag', text: this.$t('common.masseditform.operators.remove_tag') },
        { value: 'replace_tag', text: this.$t('common.masseditform.operators.replace_tag') }
      ]
    },
    multipleDateOperatorState () {
      if (this.isMultipleEditFormSubmitted) {
        if (!this.multipleDateOperator && this.multipleDateValue) {
          return false
        } else {
          return true
        }
      }
      return null
    },
    multipleDateValueState () {
      if (this.isMultipleEditFormSubmitted) {
        if (this.multipleDateOperator && !this.multipleDateValue) {
          return false
        } else if (this.multipleDateOperator && this.multipleDateOperator !== 'set_date' && this.multipleDateValue && this.multipleDateValue <= 0) {
          return false
        } else if (this.multipleDateOperator && this.multipleDateOperator === 'set_date' && !this.$moment(this.multipleDateValue, 'YYYY-MM-DD', true).isValid()) {
          return false
        } else if (this.multipleDateValue) {
          return true
        }
      }
      return null
    },
    multipleAmountOperatorState () {
      if (this.isMultipleEditFormSubmitted) {
        if (!this.multipleAmountOperator && !this.multipleAmountValue) {
          return true
        } else if (!this.multipleAmountOperator && this.multipleAmountValue) {
          return false
        } else {
          return true
        }
      }
      return null
    },
    multipleAmountValueState () {
      if (this.isMultipleEditFormSubmitted) {
        if (this.multipleAmountOperator && !this.multipleAmountValue) {
          return false
        } else {
          return true
        }
      }
      return null
    },
    multipleTitleOperatorState () {
      if (this.isMultipleEditFormSubmitted) {
        if (!this.multipleTitleOperator && this.multipleTitleValue) {
          return false
        } else {
          return true
        }
      }
      return null
    },
    multipleTitleValueState () {
      if (this.isMultipleEditFormSubmitted) {
        if (['lowercase', 'capitalize', 'upper_first'].indexOf(this.multipleTitleOperator) !== -1) {
          return null
        }

        if (this.multipleTitleOperator && !this.multipleTitleValue) {
          return false
        } else if (this.multipleTitleOperator && this.multipleTitleValue) {
          return true
        }
      }
      return null
    },
    multipleTagOperatorState () {
      if (this.isTagFormSubmitted) {
        if (!this.multipleTagOperator && this.multipleTagValue) {
          return false
        }
        return true
      }
      return null
    },
    multipleTagValueState () {
      if (this.isTagFormSubmitted) {
        if (this.multipleTagOperator && !this.multipleTagValue) {
          return false
        }
        return true
      }
      return null
    },
    multipleTagValueAdditionalState () {
      if (this.isTagFormSubmitted) {
        if (this.multipleTagOperator === 'replace_tag' && !this.multipleTagValueAdditional) {
          return false
        }
        return true
      }
      return null
    },
    amountValidationClass () {
      const extraClass = {
        'is-valid': false,
        'is-invalid': false
      }
      if (this.isMultipleEditFormSubmitted && this.multipleAmountOperator && !this.multipleAmountValue) {
        extraClass['is-invalid'] = true
        extraClass['is-valid'] = false
      } else if (this.isMultipleEditFormSubmitted && this.multipleAmountValue) {
        extraClass['is-invalid'] = false
        extraClass['is-valid'] = true
      }
      return extraClass
    },
    multipleDateOperatorInvalidFeedback () {
      if (this.isMultipleEditFormSubmitted && this.multipleDateValue && !this.multipleDateOperator) {
        return this.$t('common.masseditform.errors.select_required')
      }
      return ''
    },
    multipleDateValueInvalidFeedback () {
      if (this.isMultipleEditFormSubmitted) {
        if (this.multipleDateOperator && !this.multipleDateValue) {
          return this.$t('common.masseditform.errors.field_required')
        } else if (this.multipleDateOperator && this.multipleDateOperator !== 'set_date' && this.multipleDateValue && this.multipleDateValue <= 0) {
          return this.$t('common.masseditform.errors.non_zero_error')
        } else if (this.multipleDateOperator && this.multipleDateOperator === 'set_date' && !this.$moment(this.multipleDateValue, 'YYYY-MM-DD', true).isValid()) {
          return this.$t('common.masseditform.errors.invalid_date')
        } else {
          return ''
        }
      }
      return ''
    },
    multipleAmountOperatorInvalidFeedback () {
      if (this.isMultipleEditFormSubmitted && this.multipleAmountValue && !this.multipleAmountOperator) {
        return this.$t('common.masseditform.errors.select_required')
      }
      return ''
    },
    multipleAmountValueInvalidFeedback () {
      if (this.isMultipleEditFormSubmitted && !this.multipleAmountValue && this.multipleAmountOperator) {
        return this.$t('common.masseditform.errors.field_required')
      }
      return ''
    },
    multipleTitleOperatorInvalidFeedback () {
      if (this.isMultipleEditFormSubmitted && this.multipleTitleValue && !this.multipleAmountOperator) {
        return this.$t('common.masseditform.errors.select_required')
      }
      return ''
    },
    multipleTitleValueInvalidFeedback () {
      if (['lowercase', 'capitalize', 'upper_first'].indexOf(this.multipleTitleOperator) === -1) {
        if (this.isMultipleEditFormSubmitted && !this.multipleTitleValue && this.multipleTitleOperator) {
          return this.$t('common.masseditform.errors.field_required')
        }
      }
      return ''
    },
    multipleTagOperatorInvalidFeedback () {
      if (this.isTagFormSubmitted && this.multipleTagValue && !this.multipleTagOperator) {
        return this.$t('common.masseditform.errors.select_required')
      }
      return ''
    },
    multipleTagValueInvalidFeedback () {
      if (this.isTagFormSubmitted && !this.multipleTagValue && this.multipleTagOperator) {
        return this.$t('common.masseditform.errors.select_required')
      }
      return ''
    },
    multipleTagValueAdditionalInvalidFeedback () {
      if (this.isTagFormSubmitted && !this.multipleTagValueAdditional && this.multipleTagOperator === 'replace_tag') {
        return this.$t('common.masseditform.errors.select_required')
      }
      return ''
    },
    tagTitles () {
      const titles = []
      this.tags.map(tag => {
        titles.push(tag.title)
      })
      return titles
    }
  },
  methods: {
    hasTagActions (tagActions) {
      if (!tagActions) {
        return false
      }
      return tagActions.add_tag.length || tagActions.remove_tag.length || (Object.keys(tagActions.replace_tag)).length
    },
    handleSourceChange (val) {
      this.percentage = val.default_active_percentage
    },
    validate () {
      if (this.transactionsViewItems.length === 0 || this.validTransactions.length !== this.transactionsViewItems.length) return false
      const postData = [...this.transactionsViewItems].map((el) => {
        return {
          id: el.id,
          credit_account_id: el.credit_account.id,
          debit_account_id: el.debit_account.id
        }
      })
      return axios.post(`${process.env.VUE_APP_ROOT_API}/transactions/identify-transaction-subtype`, {
        transactions: postData
      })
        .then((response) => response.data.data)
        .then((res) => {
          const transactionWithSubType = [...this.transactionsViewItems].map((el) => {
            const filterResponseSubType = res.filter((innerEl) => innerEl.id === el.id)
            return Object.assign({}, el, {
              transaction_subtype: filterResponseSubType.length > 0 ? filterResponseSubType[0].transaction_subtype : null
            })
          })
          this.transactionsViewItems = transactionWithSubType
          this.$emit('validate-success', this.transactionsViewItems)
          return true
        })
        .catch(e => {
          console.error(e)
          return false
        })
    },
    validateValues (obj) {
      return obj && obj !== '' && obj.toString().trim().length !== 0
    },
    multipleEditHandler () {
      this.isMultipleEditFormSubmitted = true
      if (
        this.multipleDateValueState === false ||
        this.multipleDateOperatorState === false ||
        this.multipleAmountValueState === false ||
        this.multipleAmountOperatorState === false ||
        this.multipleTitleOperatorState === false ||
        this.multipleTitleValueState === false
      ) {
        return false
      }

      /* this.transactionsViewItems.map(el => {
        el.tags = _.cloneDeep(el.tagsInitial)
      }) */

      this.transactionsViewItems = [...this.transactionsViewItems].map(el => {
        const updatedObject = {}
        // updatedObject.tags = _.cloneDeep(el.tags)
        if (this.multipleCategory !== null) {
          updatedObject.credit_account = this.multipleCategory
        }
        if (this.multipleSource !== null) {
          updatedObject.debit_account = this.multipleSource
        }
        if (this.percentage !== null) {
          updatedObject.percentage = this.percentage
        }
        if (this.multipleDateOperator && this.multipleDateValue) {
          switch (this.multipleDateOperator) {
            case 'set_date':
              updatedObject.date = this.$moment(this.multipleDateValue).format('YYYY-MM-DD').toString()
              break
            case 'add_days':
              updatedObject.date = this.$moment(el.date).add(this.multipleDateValue, 'days').format('YYYY-MM-DD').toString()
              break
            case 'add_months':
              updatedObject.date = this.$moment(el.date).add(this.multipleDateValue, 'months').format('YYYY-MM-DD').toString()
              break
            case 'add_year':
              updatedObject.date = this.$moment(el.date).add(this.multipleDateValue, 'years').format('YYYY-MM-DD').toString()
              break
            case 'subtract_days':
              updatedObject.date = this.$moment(el.date).subtract(this.multipleDateValue, 'days').format('YYYY-MM-DD').toString()
              break
            case 'subtract_months':
              updatedObject.date = this.$moment(el.date).subtract(this.multipleDateValue, 'months').format('YYYY-MM-DD').toString()
              break
            case 'subtract_years':
              updatedObject.date = this.$moment(el.date).subtract(this.multipleDateValue, 'years').format('YYYY-MM-DD').toString()
              break
          }
        }
        if (this.multipleAmountOperator && this.multipleAmountValue) {
          switch (this.multipleAmountOperator) {
            case 'set_amount':
              updatedObject.amount = this.multipleAmountValue
              break
            case 'multiply_with':
              updatedObject.amount = el.amount * this.multipleAmountValue
              break
            case 'divide_by':
              updatedObject.amount = el.amount / this.multipleAmountValue
              break
            case 'subtract_by':
              updatedObject.amount = el.amount - this.multipleAmountValue
              break
            case 'add':
              updatedObject.amount = el.amount + this.multipleAmountValue
              break
          }
        }
        if (
          this.multipleTitleOperator &&
          (this.multipleTitleValue || ['lowercase', 'capitalize', 'upper_first'].indexOf(this.multipleTitleOperator) !== -1)
        ) {
          switch (this.multipleTitleOperator) {
            case 'set_title':
              updatedObject.title = this.multipleTitleValue
              break
            case 'remove_part':
              updatedObject.title = el.title.replaceAll(this.multipleTitleValue, '')
              break
            case 'append_part':
              updatedObject.title = el.title + this.multipleTitleValue
              break
            case 'prefix_part':
              updatedObject.title = this.multipleTitleValue + el.title
              break
            case 'lowercase':
              updatedObject.title = el.title.toLowerCase()
              break
            case 'capitalize':
              updatedObject.title = el.title.toUpperCase()
              break
            case 'upper_first':
              updatedObject.title = el.title.substr(0, 1).toUpperCase() + el.title.substr(1).toLowerCase()
              break
          }
        }

        updatedObject.tagActions = _.cloneDeep(this.tagSet)
        const aTmpExistedTagsToRemove = []
        const aTmpExistedTagsToReplace = []
        const aReplaceTagTitles = Object.keys(this.tagSet.replace_tag)
        el.tags.map(existedTag => {
          updatedObject.tagActions.add_tag = updatedObject.tagActions.add_tag.filter(tagTitle => tagTitle !== existedTag.title)

          if (updatedObject.tagActions.remove_tag.indexOf(existedTag.title) !== -1) {
            aTmpExistedTagsToRemove.push(existedTag.title)
          }

          if (aReplaceTagTitles.indexOf(existedTag.title) !== -1) {
            aTmpExistedTagsToReplace.push(existedTag.title)
          }
        })

        updatedObject.tagActions.remove_tag = _.cloneDeep(aTmpExistedTagsToRemove)
        updatedObject.tagsToShow = el.tags.filter(existedTag => aTmpExistedTagsToRemove.indexOf(existedTag.title) === -1)

        updatedObject.tagActions.replace_tag = {}
        for (const i in aTmpExistedTagsToReplace) {
          updatedObject.tagActions.replace_tag[aTmpExistedTagsToReplace[i]] = this.tagSet.replace_tag[aTmpExistedTagsToReplace[i]]
        }
        updatedObject.tagsToShow = updatedObject.tagsToShow.filter(existedTag => aTmpExistedTagsToReplace.indexOf(existedTag.title) === -1)

        this.handleShowDetails(updatedObject)

        if (this.selectedIndexes.indexOf(el.id) === -1) return el
        return Object.assign({}, el, updatedObject)
      })

      this.multipleDateOperator = null
      this.multipleDateValue = null
      this.multipleAmountOperator = null
      this.multipleAmountValue = 0
      this.multipleTitleOperator = null
      this.multipleTitleValue = null
      this.multipleCategory = null
      this.multipleSource = null
      this.percentage = null
      this.toggleAll(false)
      this.showMultipleEdit = false
      this.isMultipleEditFormSubmitted = false
      this.multipleTagOperator = null
      this.multipleTagValue = null
      this.multipleTagValueAdditional = null
      this.tagSet.add_tag = []
      this.tagSet.remove_tag = []
      this.tagSet.replace_tag = {}
      this.renderKey++

      this.rerenderCurrencyInputs()
    },
    updatedMultipleCategory () {
      this.transactionsViewItems = [...this.transactionsViewItems].map((el) => {
        const updatedObject = {}
        updatedObject.credit_account = this.multipleCategory
        if (this.selectedIndexes.indexOf(el.id) === -1) return el
        return Object.assign({}, el, updatedObject)
      })
      this.multipleCategory = null
    },
    updatedMultipleSource () {
      this.transactionsViewItems = [...this.transactionsViewItems].map((el) => {
        const updatedObject = {}
        updatedObject.debit_account = this.multipleSource
        if (this.selectedIndexes.indexOf(el.id) === -1) return el
        return Object.assign({}, el, updatedObject)
      })
      this.multipleSource = null
    },
    toggleAll (checked) {
      const transactionsItems = [...this.transactionsViewItems]
      if (checked) {
        transactionsItems.forEach((elem) => {
          if (this.selectedIndexes.indexOf(elem.id) === -1) {
            this.$nextTick(function () {
              this.multipleSelectedRows.push(elem)
            })
          }
        })
      } else {
        this.multipleSelectedRows = []
      }
    },
    selectCheckBoxRow (value, index) {
      if (value) {
        this.$refs.multipleEdit.selectRow(index)
      } else {
        this.$refs.multipleEdit.unselectRow(index)
      }
    },
    handleCategoryOpen (type) {
      if (!Object.prototype.hasOwnProperty.call(this.categoryOptions, type)) {
        this.loadOptions()
      }
    },
    loadOptions () {
      this.$emit('load-options')
    },
    validateSingleColumn (value, row, type) {
      if (!value && type === 'title') {
        row.item.errors.title = false
      } else if (value && type === 'title') {
        row.item.errors.title = true
      }
      if (!value && value !== 0 && type === 'amount') {
        row.item.errors.amount = false
      } else if (value && type === 'amount') {
        row.item.errors.amount = true
      }
      if (!value && type === 'date') {
        row.item.errors.date = false
      } else if (value && type === 'date') {
        row.item.errors.date = true
      }
      if (!value && type === 'credit_account') {
        row.item.errors.credit_account = false
      } else if (value && type === 'credit_account') {
        row.item.errors.credit_account = true
      }
      if (!value && type === 'debit_account') {
        row.item.errors.debit_account = false
      } else if (value && type === 'debit_account') {
        row.item.errors.debit_account = true
      }
    },
    loadTags () {
      axios.get(`${process.env.VUE_APP_ROOT_API}/tags`)
        .then(response => {
          this.tags = response.data.data
        })
        .catch(err => {
          console.error(err)
        })
    },
    onProcessTagClick () {
      this.isTagFormSubmitted = true
      if (
        this.multipleTagValueState === false ||
        this.multipleTagValueAdditionalState === false ||
        this.multipleTagOperatorState === false
      ) {
        return false
      }

      switch (this.multipleTagOperator) {
        case 'add_tag':
          this.tagSet.add_tag.push(this.multipleTagValue)
          this.tagSet.add_tag = this.tagSet.add_tag.filter((v, i, s) => s.indexOf(v) === i)
          break
        case 'remove_tag':
          this.tagSet.remove_tag.push(this.multipleTagValue)
          this.tagSet.remove_tag = this.tagSet.remove_tag.filter((v, i, s) => s.indexOf(v) === i)
          break
        case 'replace_tag':
          this.tagSet.replace_tag[this.multipleTagValue] = this.multipleTagValueAdditional
          break
      }
      this.multipleTagValue = null
      this.multipleTagValueAdditional = null
      this.renderKey++

      this.isTagFormSubmitted = false
    },
    onTagDeleteClick (tag, action) {
      if (action === 'replace_tag') {
        delete this.tagSet.replace_tag[tag]
      } else {
        this.tagSet[action] = this.tagSet[action].filter(v => v !== tag)
      }
      this.renderKey++
    },
    onExistedTagDeleteClick (tag, transaction) {
      transaction.tagActions.remove_tag.push(tag.title)
      transaction.tagsToShow = transaction.tagsToShow.filter(t => t.title !== tag.title)
      this.handleShowDetails(transaction)
      this.renderKey++
    },
    onAddedTagDeleteClick (tagTitle, transaction) {
      transaction.tagActions.add_tag = transaction.tagActions.add_tag.filter(t => t !== tagTitle)
      this.handleShowDetails(transaction)
      this.renderKey++
    },
    onUndoTagDeleteClick (tagTitle, transaction) {
      transaction.tagActions.remove_tag = transaction.tagActions.remove_tag.filter(t => t !== tagTitle)
      for (const i in transaction.tags) {
        if (transaction.tags[i].title === tagTitle) {
          transaction.tagsToShow.push(_.cloneDeep(transaction.tags[i]))
          break
        }
      }
      this.handleShowDetails(transaction)
      this.renderKey++
    },
    onUndoTagReplaceClick (tagTitleSource, tagTitleTarget, transaction) {
      delete transaction.tagActions.replace_tag[tagTitleSource]
      for (const i in transaction.tags) {
        if (transaction.tags[i].title === tagTitleSource) {
          transaction.tagsToShow.push(_.cloneDeep(transaction.tags[i]))
          break
        }
      }
      this.handleShowDetails(transaction)
      this.renderKey++
    },
    handleShowDetails (transaction) {
      if (transaction.tagsToShow.length || this.hasTagActions(transaction.tagActions)) {
        transaction._showDetails = true
      } else {
        transaction._showDetails = false
      }
    }
  },
  created () {
    this.loadTags()
  },
  watch: {
    multipleSelectedRows (newVal) {
      if (newVal.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newVal.length === this.$refs.multipleEdit.items.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
    transactions: {
      handler: function (value, oldValue) {
        this.transactionsViewItems = _.cloneDeep(value)
        this.transactionsViewItems.map(t => {
          this.handleShowDetails(t)
        })
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
.component-recategorize-wizard-step01 {
  .card.table-wrapper {
    border: none;
    overflow-x: auto;
    position: static;
  }

  .td-date{min-width: 140px;max-width: 140px;width:140px;input{padding:5px 3px}}
  .td-title{min-width: 200px;max-width: 200px;width:200px;input{padding:5px 3px}}
  .td-amount{min-width: 130px;max-width: 130px;width:130px;text-align:right;input{padding:5px 3px}}
  .td-percentage{min-width: 100px;max-width: 100px;width:100px;padding:0;}
  .td-checkbox{text-align: right;}
  .tag {
    button {
      display: inline;
      border: none;
      background-color: transparent;
      box-shadow: none;
      i {
        font-size: 12px;
      }
      &:hover, &:active, &:focus {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
      }
    }
    &:hover {}
  }
}
</style>
